<template>
  <div>
    <el-form class="form" ref="form" :rules="rules" :model="formData">
      <el-form-item prop="account" ref="account">
        <el-input placeholder="请输入手机号" maxlength="11" autofocus v-model="formData.account" @keyup.enter.native="onSubmit" key="account"
          clearable></el-input>
      </el-form-item>
      <el-form-item prop="authCode" class="authCode" ref="authCode">
        <el-input placeholder="请输入验证码" maxlength="6" v-model="formData.authCode" @keyup.enter.native="onSubmit">
          <el-button slot="append" @click="getAuthCode" round>{{ 0 >= Countdown ? '获取验证码' : Countdown + 's'
          }}</el-button>
        </el-input>

      </el-form-item>
        <el-form-item prop="password" ref="password"> 
            <el-input
            @blur="comparePassword"
            type="password"
            maxlength="20"
            autocomplete="new-password"
            placeholder="请设置8-20位，数字、大小写字母、字符组合密码"
            v-model="formData.password"
            ></el-input>
        </el-form-item>
        <el-form-item class="rePassword" prop="rePassword" ref="rePassword"> 
            <el-input
            @blur="comparePassword"
            placeholder="请再次输入登录密码"
            :type="pass"
            maxlength="20"
            autocomplete="new-password"
            v-model="formData.rePassword"
            >
            <template slot="suffix">
                <span class="password pwd-icon" v-show="formData.rePassword && pass === 'password'" @click="handleshowPsd">
                <svg-icon icon-class="yanjing-1"></svg-icon>
                </span>
                <span class="password pwd-icon" v-show="formData.rePassword && pass === 'text'" @click="handleshowPsd">
                <svg-icon icon-class="yanjing-2"></svg-icon>
                </span>
            </template>
            </el-input>
        </el-form-item>
    </el-form>
    <div class="bottom">
      <div class="read">
        <el-checkbox v-model="hasreaded">
          <span>我同意</span>
          <span class="link" @click.stop="goPage(1)">《用户协议》</span>
          <span>和</span>
          <span class="link"  @click.stop="goPage(2)">《法律声明及隐私政策》</span>
        </el-checkbox>
      </div>
      <div class="loginBottom">
        <p class="loginBtn" @click="onSubmit">立即注册</p>
        <p class="forget">已有账号？<span @click="toLogin()">立即登录 ></span></p>
      </div>
    </div>
  </div>
</template>

<script>
import { getConfigureValue } from '@/utils/configure.js'
import { phoneValidator, isPhone,passwordValidator } from '@/utils/validator'
import isOfficial from '@/utils/common'

export default {
  data() {
    return {
      privacyURL:'https://wd-static-page.wdeduc.com/wd-yi-agreement/privacy.html',
      agreementURL:'https://wd-static-page.wdeduc.com/wd-yi-agreement/service-agreement.html',
      pass: 'password',
      hasreaded: false,
      CountdownInterval: null,
      Countdown: 0,
      activeName: 'user',
      tabsList: [
        { key: "user", val: "手机号注册" },
      ],
      formData: {
        account: null,
        password: null,
        authCode: null,
        rePassword:null
      },
      wdObj:getConfigureValue('yjgw_wd_base') && JSON.parse(getConfigureValue('yjgw_wd_base')),
      rules: {
        account: [
          {
            validator: phoneValidator,
            trigger: "blur"
          }
        ],
        authCode: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          { min: 6, max: 6, message: "请输入六位验证码", trigger: "blur" }
        ],
        password:[
          { required: true, message: '登录密码不能为空', trigger: 'blur' },
          { validator: passwordValidator, trigger: 'blur' }
        ],
        rePassword:[{ required: true, message: '登录密码不能为空', trigger: 'blur' }]
      },
    }
  },
  methods: {
    //   隐私协议跳转
    goPage(type){
        let URL = type === 1 ? this.agreementURL  : this.privacyURL;
        window.open(URL)
    },
    handleshowPsd() {
      if (this.pass == 'password') {
        this.pass = 'text'
      } else {
        this.pass = 'password'
      }
    },
    toLogin(){
      this.$router.push({
        path:'/login',
        query:{
          from:'user'
        }
      })
    },
    setCountdown() {
      this.CountdownInterval = setInterval(() => {
        this.Countdown--;
        if (this.Countdown <= 0) {
          clearInterval(this.CountdownInterval);
        }
      }, 1000);
    },
    // 获取验证码
    async getAuthCode() {
      const data = { account: this.formData.account, type: 1 };
      this.formData.authCode = null;
      if (!this.formData.account) {
        this.$message.warning('请填写手机号');
        return false;
      }
      if (!isPhone.test(this.formData.account)) {
        this.$message.warning('请填写正确的手机号');
        return false;
      }
      if (this.Countdown > 0) return;
      this.Countdown = 60;
      this.setCountdown();
      this.$api.usercenter.postAccountGetAuthCode({ data })
    },
    // 立即注册
    async onSubmit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (!this.hasreaded) {
            this.$message.warning('请勾选用户协议')
            return false;
          }
          if(!this.comparePassword()) return
          this.postAccountRegistByAuthCode()
        } else {
          return false
        }
      })
    },
    async postAccountRegistByAuthCode () {
      const data = {
        ...this.formData,
        branchUcode:isOfficial?this.wdObj?.ucode:undefined,
      }
      const that = this;
      await this.$api.usercenter.postAccountRegister({ data }).then(res => {
        if (res.success) {
          this.$message({
             message: '注册成功',
             type:'success',
             duration:2000,
             onClose:function(){
                that.$router.push({
                    path:'/login',
                    query:{
                        from:'user',
                        account:that.formData.account
                    }
                })
             }
          });
        }
      })
    },
    comparePassword(){
      let result = true
      if(this.formData.password && this.formData.rePassword){
        if(this.formData.password !== this.formData.rePassword){
          this.$message.warning('两次密码输入不一致')
          result = false
        }
      }
      return result
    },
  }
}
</script>

<style lang="scss" src="../asset/css/login.scss" scoped>

</style>