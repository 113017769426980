<!-- 
 * @description: 政府/协会注册
 * @fileName: register-government.vue 
 * @date: 2024-05-08 13:26:50
!-->
<template>
  <div>
    <el-form class="form" ref="form" :rules="rules" :model="formData" :inline="true" label-width="100px">
      <el-form-item prop="name" label="单位名称：">
        <el-input placeholder="请输入单位名称"  autofocus v-model="formData.name" 
          clearable></el-input>
      </el-form-item>
      <el-form-item prop="userName" label="联系人：">
        <el-input placeholder="请输入联系人姓名"  autofocus v-model="formData.userName" clearable></el-input>
      </el-form-item>
      <el-form-item prop="phone" label="联系电话：">
        <el-input placeholder="请输入手机号" maxlength="11" autofocus v-model="formData.phone" clearable></el-input>
      </el-form-item>
      <el-form-item prop="authCode" ref="authCode" class="emptyLabel">
        <el-input placeholder="请输入验证码" maxlength="6" v-model="formData.authCode" @keyup.enter.native="onSubmit">
          <el-button slot="append" @click="getAuthCode" round>{{ 0 >= Countdown ? '获取验证码' : Countdown + 's'
          }}</el-button>
        </el-input>
      </el-form-item>
        <el-form-item label="所属上级：" class="needRequred" style="margin-bottom:20px">
            <el-row type="flex" justify="space-between">
                <el-col class="selectItem province">
                    <el-form-item prop="province" style="margin-bottom:0">
                        <el-select v-model="formData.province" size="small" placeholder="请选择省" @change="handleProvinceChange">
                            <el-option
                                v-for="item in provinceList"
                                :key="item.code"
                                :label="item.name"
                                :value="item.code">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col class="selectItem city">
                    <el-form-item prop="city" style="margin-bottom:0">
                        <el-select v-model="formData.city" size="small" placeholder="请选择市" @change="handleCityChange">
                            <el-option
                                v-for="item in cityList"
                                :key="item.code"
                                :label="item.name"
                                :value="item.code">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col class="selectItem areaSelect">
                    <el-form-item prop="district" style="margin-bottom:0">
                        <el-select v-model="formData.district" size="small" placeholder="请选择区">
                            <el-option
                                v-for="item in districtList"
                                :key="item.code"
                                :label="item.name"
                                :value="item.code">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
      </el-form-item>
      <el-form-item prop="addressDetail" label="详细地址：">
        <el-input placeholder="请输入详细地址"  autofocus v-model="formData.addressDetail"
          clearable></el-input>
      </el-form-item>
    </el-form>
    <div class="bottom">
      <div class="read">
        <el-checkbox v-model="hasreaded">
          <span>注册即代表同意</span>
          <span class="link" @click.stop="goPage(1)">《用户协议》</span>
          <span>和</span>
          <span class="link"  @click.stop="goPage(2)">《法律声明及隐私政策》</span>
        </el-checkbox>
      </div>
      <div class="loginBottom">
        <p class="loginBtn" @click="onSubmit">立即注册</p>
        <p class="forget">已有账号？<router-link :to="{path:'/login',query:{from:'govt'}}">立即登录 ></router-link></p>
      </div>
    </div>
  </div>
</template>

<script>
import { phoneValidator, isPhone } from '@/utils/validator'
export default {
  data() {
    return {
      privacyURL:'https://wd-static-page.wdeduc.com/wd-yi-agreement/privacy.html',
      agreementURL:'https://wd-static-page.wdeduc.com/wd-yi-agreement/service-agreement.html',
      hasreaded: false,
      CountdownInterval: null,
      Countdown: 0,
      provinceList:[],
      province:'',
      city:'',
      district:'',
      formData: {
        phone: null,
        authCode: null,
        province:'',
        city:'',
        district:'',
      },
      rules: {
        phone: [
          {
            validator: phoneValidator,
            trigger: "blur",
            required:true
          }
        ],
        province:{required:true,message:'请选择省',trigger:'change' },
        city:{required:true,message:'请选择市',trigger:'change' },
        district:{required:true,message:'请选择区',trigger:'change' },
        name:{required:true,message:'请输入单位名称',trigger:'blur' },
        userName:{required:true,message:'请输入联系人姓名',trigger:'blur' },
        addressDetail:{required:true,message:'请输入详细地址',trigger:'blur' },
        authCode: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          { min: 6, max: 6, message: "请输入六位验证码", trigger: "blur" }
        ]
      },
    }
  },
  computed:{
    cityList(){
      if(this.formData.province){
          let findItem = this.provinceList.find(item => item.code === this.formData.province)
          return findItem && findItem.children
      }else{
          return []
      }
    },
    districtList(){
      if(this.formData.city){
          let findItem = this.cityList.find(item => item.code === this.formData.city)
          return findItem && findItem.children
      }else{
          return []
      }
    }
  },
  created(){
    this.getRegionList()
  },
  methods: {
    //   隐私协议跳转
    goPage(type){
      let URL = type === 1 ? this.agreementURL  : this.privacyURL;
      window.open(URL)
    },
    setCountdown() {
      this.CountdownInterval = setInterval(() => {
        this.Countdown--;
        if (this.Countdown <= 0) {
          clearInterval(this.CountdownInterval);
        }
      }, 1000);
    },
    // 获取验证码
    async getAuthCode() {
      const data = { account: this.formData.phone, type: 1 };
      this.formData.authCode = null;
      if (!this.formData.phone) {
        this.$message.warning('请填写手机号');
        return false;
      }
      if (!isPhone.test(this.formData.phone)) {
        this.$message.warning('请填写正确的手机号');
        return false;
      }
      if (this.Countdown > 0) return;
      this.Countdown = 60;
      this.setCountdown();
      this.$api.usercenter.postAccountGetAuthCode({ data })
    },
    // 立即注册
    async onSubmit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (!this.hasreaded) {
            this.$message.warning('请勾选用户协议')
            return false;
          }
          this.postAccountRegistByAuthCode()
        } else {
          return false
        }
      })
    },
    async postAccountRegistByAuthCode () {
      const data = {
        ...this.formData
      }
      const that = this;
      await this.$api.usercenter.branchApplyRecord({ data }).then(res => {
        if (res.success) {
          this.$message({
             message: '注册成功',
             type:'success',
             duration:2000,
             onClose:function(){
                that.$router.push({
                    path:'/login',
                    query:{
                        from:'govt',
                        account:that.formData.phone
                    }
                })
             }
          });
        }
      })
    },
    getRegionList(){
      this.$api.custom.getTree({type:1}).then(res => {
        if(res.success && res.data){
          let filterArr = res.data.filter(item => {
              return item.code.length === 2 && item.children
          })
          let uniqueArr = Array.from(new Set(filterArr))
          this.provinceList = uniqueArr
        }
      })
    },
    handleProvinceChange(){
        this.formData.city = ''
        this.formData.district = ''
        this.$nextTick(() => {
            this.$refs.form.clearValidate(['city','district'])
        })
    },
    handleCityChange(){
        this.formData.district = ''
        this.$nextTick(() => {
            this.$refs.form.clearValidate('district')
        })
    },
  }
}
</script>

<style lang="scss" src="../asset/css/login.scss" scoped>

</style>